<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item>售后记录</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">售后记录</div>
                <div class="box_right_cent">
                    <div class="sales-box">
                        <div class="sales-top">
                           <div class="sales-text">若您购买的商品出现问题，可以在这里申请相关售后服务。</div>
                           <div class="sales-exchange flex-between">
                               <div class="exchange flex-start">
                                   <div class="sales-exchange-btn" @click="applyorder()">申请返修退换货</div> 
                                    <span>查询售后记录</span>
                               </div>
                               <!-- <div>联系客服</div> -->
                           </div>
                           <div class="flex-start sales-query">
                               <div class="flex-start query-box">
                                   <div class="query-text">订单号：</div>
                                   <el-input v-model="orderNum" class="query-input"></el-input>
                               </div>
                               <div class="flex-start query-box">
                                   <div class="query-text">处理结果：</div>
                                   <el-select v-model="value" placeholder="请选择" class="query-select">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                               </div>
                               <div class="flex-start query-box">
                                   <div class="query-texts">查询时间：</div>
                                   <el-date-picker
                                        v-model="value1"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间"
                                        :picker-options="pickerBeginDateBefore"
                                    >
                                    </el-date-picker>
                                   <div class="query-zhi">至</div>
                                   <el-date-picker
                                        v-model="value2"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间"
                                        :picker-options="pickerBeginDateBefore"
                                    >
                                    </el-date-picker>
                               </div>
                               <div class="flex-start">
                                   <div class="query-btn" @click="demand()">查询</div>
                                   <!-- <div class="export-btn">导出</div> -->
                               </div>
                           </div>
                        </div>
                        <div class="sales-cent">
                            <el-table
                            :data="tableData"
                            border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                            :cell-style="{ textAlign: 'center' }"
                            style="width: 100%;text-align: center;" >
                              <!-- <el-table-column
                                type="selection"
                                width="60">
                              </el-table-column>@selection-change="handleSelectionChange" -->
                                <el-table-column
                                prop="afterSaleNo"
                                label="申请订单单号">
                                </el-table-column>
                                <el-table-column
                                prop="orderNo"
                                label="订单编号">
                                </el-table-column>
                                <el-table-column
                                prop="remark"
                                label="商品名称">
                                </el-table-column>
                                <el-table-column
                                prop="goodsTotalNum"
                                label="数量"
                                width="80">
                                </el-table-column>
                                <el-table-column
                                prop="createTime"
                                label="申请时间"
                                width="130">
                                </el-table-column>
                                <el-table-column
                                prop="type"
                                label="售后类型"
                                width="100">
                                <template slot-scope="scope">
                                    {{typeName(scope.row.type)}}
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="state"
                                label="处理结果"
                                width="120">
                                <template slot-scope="scope">
                                    {{stateName(scope.row.state)}}
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="addresss"
                                label="操作"
                                width="100">
                                <template slot-scope="scope">
                                  <div v-show="scope.row.state==1 || scope.row.state==2">
                                    <el-button type="text" size="small" @click="withdraw(scope.$index,scope.row)">取消申请</el-button>
                                  </div>
                                  <div v-show="scope.row.state==6 || scope.row.state==7">
                                    <el-button type="text" size="small" @click="applyAgain(scope.$index,scope.row)">重新申请</el-button>
                                  </div>
                                  <div v-show="scope.row.state==2">
                                    <el-button type="text" size="small" @click="fillLogistics(scope.$index,scope.row)">填写退货物流</el-button>
                                  </div>
                                  <div v-show="scope.row.state==9">
                                    <el-button type="text" size="small" @click="salesConfirm(scope.row)">确认</el-button>
                                  </div>
                                  <!-- <div v-show="scope.row.state==3 || scope.row.state==4 || scope.row.state==8 || scope.row.state==9">
                                    <el-button @click="handleClick(scope.$index,scope.row)" type="text" size="small">查看</el-button>
                                  </div> -->
                                  <!-- <div  v-show="scope.row.state==5">
                                    <el-button @click="handleClick(scope.$index,scope.row)" type="text" size="small">查看</el-button>
                                  </div> -->
                                  <el-button @click="handleClick(scope.$index,scope.row)" type="text" size="small">查看</el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                            <div class="block" v-show="tableData.length!=0">
                                <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="currentPage"
                                :page-size="10"
                                layout="prev, pager, next, jumper"
                                :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                  <el-dialog
                    title="填写物流信息"
                    :visible.sync="dialogVisible"
                    width="30%">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                      <el-form-item label="快递公司" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                      </el-form-item>
                      <el-form-item label="快递单号" prop="number">
                        <el-input v-model="ruleForm.number"></el-input>
                      </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                      <el-button @click="dialogVisible = false">取 消</el-button>
                      <el-button type="primary" @click="countersign()">确 定</el-button>
                    </span>
                  </el-dialog>

                </div>
                
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { afterSale,cancelsales,logisticsinformat,viewdetails,changeGoodsComplete } from "@/api/order/aftersale"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        currentPage:1,
        ruleForm: {
          name: '',
          number:''

        },
        rules: {
          name: [
            { required: true, message: '请输入快递公司', trigger: 'blur' },
          ],
          number: [
            { required: true, message: '请输入快递单号', trigger: 'blur' },
          ],
        },
        tableData: [],
        options: [{
          value: '1',
          label: '等待管理员审批'
        }, {
          value: '8',
          label: '等待供应商确认地址'
        }, {
          value: '2',
          label: '等待用户寄回商品'
        }, {
          value: '3',
          label: '等待管理员检验'
        }, {
          value: '4',
          label: '等待财务审核'
        },{
          value: '5',
          label: '已完成'
        },{
          value: '6',
          label: '商家拒绝退款'
        },{
          value: '7',
          label: '买家取消售后'
        },{
          value: '9',
          label: '等待用户确认'
        }],
        value: '',
        multipleSelection: [],
        total:0,
        pageNum:1,
        pageSize:10,
        orderNum:'',
        value1:'',
        value2:'',
        dialogVisible: false,
        afterSaleId:'',//售后id
        pickerBeginDateBefore:{
          disabledDate(time) {
              return time.getTime() > Date.now();
          }
        },
      }
  },
  computed: {
    typeName() {
        return (type) => {
            switch (type) {
                case 1:
                    return "退款";
                case 2:
                    return "退货退款";
                case 5:
                    return "换货";
                case 6:
                    return "返修";
            }
        };
    },
    stateName(){
      return (state) => {
        switch (state) {
          case 1:
            return "申请退款";
          case 2:
            return "已确认收货地址，等待用户寄回商品";
          case 3:
            return "买家寄回商品等待管理员检验";
          case 4:
            return "审核通过，等待财务审核";
          case 5:
            return "已完成";
          case 6:
            return "商家拒绝退款";
          case 7:
            return "买家取消售后";
          case 8:
            return "管理员同意退款，等待供应商确认地址";
          case 9:
            return "商家寄出商品，等待用户确认";
          case 10:
            return "申请的售后商品有活动商品，需要先通过客服审核后再到供应商审核";
          case 11:
            return "商家验货不通过，等待商家寄回客户的商品";
        }
      };
    }
  },
  created() {
    if (this.$route.params.searchId) {
        this.orderNum = this.$route.params.searchId
        this.afterSale('','',this.orderNum,this.pageNum,this.pageSize,'')
    } else {
        this.afterSale('','','',this.pageNum,this.pageSize,'')
    }
    // changeGoodsComplete(11756).then(res=>{
    //         console.log(res);
    //         if(res.code == '00000'){
    //           this.$message({
    //             type: 'success',
    //             message: '执行成功！'
    //           });
    //           this.pageNum = 1
    //           this.afterSale(this.value1,this.value2,this.orderNum,this.pageNum,this.pageSize,this.value)
    //         }
    //       })
  },
  methods:{
      addTicket(){
        this.$router.push({ name: 'AddTicket' })
      },
      applyorder(){
        this.$router.push({ name: 'ChangingRefund' })
      },
      handleSizeChange(val) {
        this.afterSale(this.value1,this.value2,this.orderNum,val,this.pageSize,this.value)
      },
      handleCurrentChange(val) {
        this.afterSale(this.value1,this.value2,this.orderNum,val,this.pageSize,this.value)
      },
      //查询售后接口
      afterSale(beginTime,endTime,orderNo,pageNum,pageSize,state){
        let data={
          "beginTime": beginTime,
          "endTime": endTime,
          "orderNo": orderNo,
          "pageNum": pageNum,
          "pageSize": pageSize,
          "state":state
        }
        afterSale(data).then(data=>{
          if(data.code=='00000'){
            if(data.data.records.length==0){
              this.tableData=[]
            }else{
              this.tableData=data.data.records
              this.total=data.data.total
            }
          }
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //查询售后
      demand(){
        let data = (new Date(this.value1)).getTime()
        let data2 = (new Date(this.value2)).getTime()
        if(data>data2){
            this.$message({
                type: "error",
                message: "开始时间不可以超过结束时间!",
            });
        }else{
          this.afterSale(this.value1,this.value2,this.orderNum,this.pageNum,this.pageSize,this.value)
        }
      },
      //取消申请
      withdraw(index,row){
        this.$confirm('确认取消售后', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancelsales(row.id).then((data)=>{
            if(data.code=='00000'){
              this.$message({
                type: 'success',
                message: '取消成功!'
              });
              this.afterSale('','','',this.pageNum,this.pageSize,'')
            }else{
              this.$message.error(data.desc);
            }
          })
        }).catch(() => {});
      },
      //查看售后信息
      handleClick(index,row){
        this.$router.push({ name: "ReturnGoods",  query: { id: row.id},});
      },
      //填写售后物流信息
      fillLogistics(index,row){
        this.dialogVisible=true
        this.afterSaleId=row.id
      },
      countersign(){
        this.$refs['ruleForm'].validate((valid) => {
          if(valid){
            let data={
              "afterSaleId": this.afterSaleId,
              "sendCompanyAndNo": this.ruleForm.name +"_"+this.ruleForm.number
            }
            logisticsinformat(data).then((data)=>{
              if(data.code==0){
                this.$message({
                  type: 'success',
                  message: '填写成功!'
                });
                this.dialogVisible=false
                this.afterSale('','','',this.pageNum,this.pageSize)
              }
            })
          }
        })
      },
      //重新申请
      applyAgain(index,row){
        console.log(index,row)
         this.$confirm('确认重新申请', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let id = row.id
          viewdetails(id).then((data)=>{
            if(data && data.code=='00000'){
              this.$router.push({ name: "ApplySales",  query: { name:JSON.stringify(row),list:JSON.stringify(data.data)},});
            }
          })
        })
        
      },
      // 售后确认按钮
      salesConfirm(row){
        // console.log(row);
        this.$confirm('确认操作', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          changeGoodsComplete({afterSaleId:row.id}).then(res=>{
            console.log(res);
            if(res.code == '00000'){
              this.$message({
                type: 'success',
                message: '执行成功！'
              });
              this.pageNum = 1
              this.afterSale(this.value1,this.value2,this.orderNum,this.pageNum,this.pageSize,this.value)
            } else {
              this.$message({
                type: 'error',
                message: '执行失败！'
              });
            }
          })
        })
        
      }


  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .sales-box{
        .sales-top{
            padding: 20px;
            font-size: 15px;
            .sales-text{
                margin-bottom: 20px;
            }
            .sales-exchange{
                margin-bottom: 20px;
                .exchange>span{
                    color: #CD9F49;
                    border-bottom: 1px solid #CD9F49;
                    margin-left: 15px;
                }
                .sales-exchange-btn {
                  cursor: pointer;
                  &:hover {
                    color: #CD9F49;
                  }
                }
            }
            .sales-query{
              flex-wrap: wrap;
              width: 100%;

                .query-box{
                    margin: 0 20px 10px 0;
                }
                .query-text{
                    width: 37%;
                }
                .query-texts{
                  width: 19%;
                }
                .query-select{
                    width: 50%;
                    flex-grow: 1;
                }
                .query-input{
                   width: 50%;
                   flex-grow: 1;
                }
                .query-inputs{
                   width: 35%;
                }
                .query-zhi{
                    margin: 0 10px;
                }
                .query-btn{
                    width: 55px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    background: #CD9F49;
                    border-radius: 4px;
                    margin-left: 10px;
                    cursor: pointer;
                }
                .export-btn{
                    width: 55px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    font-size: 14px;
                    margin: 0 15px;
                    border: 1px solid #999;
                    color: #999;
                    border-radius: 4px;
                }
            }
        }
        
    }
    .sales-cent{
        margin-bottom: 30px;
        .block{
            text-align: center;
            margin: 15px 0;
        }
        .el-button--text{
          color: #CD9F49;
        }
    }
   
  }
}
</style>